import "bootstrap"
//import "./Vendors/BigPicture/BigPicture";

// Сразу создаём переменные
let navbar = document.getElementById('navbar').classList
let active_class = "navbar_scrolled"

/**
 * Слушаем событие прокрутки
 */
window.addEventListener('scroll', e => {
    if(pageYOffset > 500) navbar.add(active_class)
    else navbar.remove(active_class)
})


console.log('global.js - Made with love ❤️  - <@> 🐳')


